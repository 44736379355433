<script setup lang="ts">
  import { A11y } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import type { PloneDocument } from '~/types/Plone'

  const props = withDefaults(defineProps<{
    loop?: boolean
    fullWidth?: boolean
    controlsPosition?: 'right' | 'top'
    slidesPerView?: number
    slidesPerViewFixed?: number
    slides: PloneDocument[]
  }>(), {
    loop: false,
    slidesPerView: 1,
    controlsPosition: 'top',
  })

  const swiperInstance = ref()

  const onSwiper = swiper => {
    swiperInstance.value = swiper
  }

  const slidesPerViewMap = {
    1: {
      320: { slidesPerView: 1.25, spaceBetween: 16 },
      640: { slidesPerView: 2, spaceBetween: 20 },
      1350: { slidesPerView: 2.4, spaceBetween: 24 },
      1600: { slidesPerView: 2.8, spaceBetween: 24 },
      1921: { slidesPerView: 3.2, spaceBetween: 24 },
    },
    2: {
      320: { slidesPerView: 1.25, spaceBetween: 16 },
      640: { slidesPerView: 2, spaceBetween: 20 },
      1350: { slidesPerView: 3, spaceBetween: 24 },
      1600: { slidesPerView: 3.4, spaceBetween: 24 },
      1921: { slidesPerView: 4, spaceBetween: 24 },
    },
    3: {
      320: { slidesPerView: 1.25, spaceBetween: 16 },
      640: { slidesPerView: 3, spaceBetween: 20 },
      1350: { slidesPerView: 4, spaceBetween: 24 },
      1921: { slidesPerView: 5, spaceBetween: 24 },
    },
    4: {
      320: { slidesPerView: 2, spaceBetween: 16 },
      640: { slidesPerView: 4, spaceBetween: 20 },
      1350: { slidesPerView: 6, spaceBetween: 24 },
      1921: { slidesPerView: 7, spaceBetween: 24 },
    },
    6: {
      320: { slidesPerView: 2, spaceBetween: 16 },
      640: { slidesPerView: 4, spaceBetween: 20 },
      1350: { slidesPerView: 7.5, spaceBetween: 24 },
      1921: { slidesPerView: 8.5, spaceBetween: 24 },
    },
  }
</script>

<template>
  <div :class="[
    'app-slider',
    `app-slider--pv-${slidesPerView}`,
    `app-slider--controls-${controlsPosition}`,
    {
      'app-slider--loaded': swiperInstance
    }]">
    <nav
      v-if="swiperInstance && !(swiperInstance.isBeginning && swiperInstance.isEnd)"
      :class="[
        'app-slider__controls',
        `app-slider__controls--${controlsPosition}`,
        { 'app-slider__controls--full-width': fullWidth }
      ]">
      <app-button
        square
        white
        @click="swiperInstance?.slidePrev()"
        title="Předchozí">
        <mdicon name="chevron-left"/>
      </app-button>
      <app-button
        square
        white
        @click="swiperInstance?.slideNext()"
        title="Další">
        <mdicon name="chevron-right"/>
      </app-button>
    </nav>
    <swiper
      :slides-per-view="slidesPerViewFixed ? slidesPerViewFixed : slidesPerView"
      :space-between="24"
      :loopedSlides="slides.length - slidesPerView"
      :breakpoints="!slidesPerViewFixed ? slidesPerViewMap[slidesPerView] : null"
      @swiper="onSwiper"
      :modules="[A11y]"
      :loop="loop"
      :centered-slides="loop">
      <swiper-slide
        class="app-slider__slide"
        v-for="(slide, index) in slides">
        <slot
          :slide="slide"
          :index="index"/>
      </swiper-slide>
    </swiper>
  </div>
</template>

<style scoped>
  .app-slider {
    position: relative;
    transition: var(--s-fast);
    &:not(.app-slider--loaded) {
      &:deep(.swiper-wrapper) {
        display: flex;
        gap: var(--gap-400);
      }
    }
  }

  .app-slider--controls-top {
    margin-top: calc(var(--gap-600) + var(--gap-500));
    @media (--md) {
      margin-top: 0;
    }
  }

  .app-slider--controls-right {
    margin-top: var(--gap-700);
    @media (--sm) {
      margin-top: 0;
    }
  }

  .app-slider--loaded {
    aspect-ratio: inherit;
    overflow: initial;
  }

  .app-slider__controls {
    display: flex;
    justify-content: flex-end;
    padding: var(--gap-200) 0 var(--gap-300) var(--gap-200);
    gap: var(--gap-200);
    @media (--md) {
      gap: var(--gap-100);
      padding: var(--gap-200) 0 var(--gap-200) var(--gap-200);
    }
  }

  .app-slider__controls--top {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .app-slider__controls--right {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    @media (--sm) {
      top: 0;
      right: 0;
      transform: translateX(100%);
      padding-top: 0;
    }
  }

  .app-slider__controls--full-width {
    padding-right: var(--gap-400);
    @media (--md){
      padding-right: var(--gap-200);
    }
  }

  .app-slider :deep(.swiper-slide) {
    height: auto;
    align-self: stretch;
  }

  :global(.app-slider__slide > .app-card) {
    height: 100%;
  }

  .app-slider--pv-1:not(.app-slider--loaded) {
    &:deep(.app-card__wrap),
    &:deep(img) {
      opacity: 0;
    }

    &:deep(.app-slider__slide) {
      width: 80%;

      @media (min-width: 640px) {
        width: 50%;
      }
      @media (min-width: 1350px) {
        width: 41%;
      }
      @media (min-width: 1600px) {
        width: 34.5%;
      }
      @media (min-width: 1921px) {
        width: 31.25%;
      }
    }
  }

  .app-slider--pv-2:not(.app-slider--loaded) :deep(.app-slider__slide) {
    width: 80%;
    @media (min-width: 640px) {
      width: 50%;
    }
    @media (min-width: 1350px) {
      width: 33%;
    }
    @media (min-width: 1600px) {
      width: 29.4%;
    }
    @media (min-width: 1921px) {
      width: 25%;
    }
  }

  .app-slider--pv-3:not(.app-slider--loaded) :deep(.app-slider__slide) {
    width: 80%;
    @media (min-width: 640px) {
      width: 33%;
    }
    @media (min-width: 1350px) {
      width: 25%;
    }
    @media (min-width: 1921px) {
      width: 20%;
    }
  }

  .app-slider--pv-4:not(.app-slider--loaded) :deep(.app-slider__slide) {
    width: 50%;
    @media (min-width: 640px) {
      width: 25%;
    }
    @media (min-width: 1350px) {
      width: 16.6%;
    }
    @media (min-width: 1921px) {
      width: 14.28%;
    }
  }

  .app-slider--pv-6:not(.app-slider--loaded) :deep(.app-slider__slide) {
    width: 50%;
    @media (min-width: 640px) {
      width: 25%;
    }
    @media (min-width: 1350px) {
      width: 13.3%;
    }
    @media (min-width: 1921px) {
      width: 11.76%;
    }
  }
</style>
